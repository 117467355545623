export const environment = {
  stand: 'demo',
  lastBuildTime: '05-11-2024 14:30:24',
  version: '24.6.0',
  production: true,
  traceRoute: true,
  hrmImageEndpoint: 'https://hrm.bimteam.ru/api/v2',
  fmEndpoint: 'https://fm-api.bimteam.app/v1',
  locale: 'en-US',
  metrikaWebvisor: true,
  accessLink: 'https://t.me/alex_zaytsev72',
  isGoogleIntegrationEnabled: true,
  supportLink: 'https://jira.pik.ru/servicedesk/customer/portal/2/create/129',

  auth: {
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    redirectUri: window.location.origin + '/implicit-redirect',
    requireHttps: false,
    showDebugInformation: true,
    disableAtHashCheck: true,
    sendAccessToken: true,
    issuer: 'https://auth.bimteam.app/realms/master',
    clientId: 'fm_api',
    scope: 'email',
    responseType: 'id_token token',
    allowedUrls: ['https://fm-api.bimteam.app'],
  },
};
